import { QueryKey, useMutation, useQuery } from 'react-query';
import { AxiosError } from 'axios';

import APIRequest from '+services/api-services';
import { IKoraRateResponse, IMarkupResponse, IProviderRateResponse, IUpdateMarkUpData } from '+types';
import { logError } from '+utils';

const api = new APIRequest();

const createFeedbackInitValues = () => ({
  title: '',
  message: '',
  type: 'danger',
  callback: undefined,
  statusCode: undefined,
  isActive: false,
  isClosable: true,
  componentLevel: true
});

const useFetchData = <TData>(
  queryKey: QueryKey,
  queryFn: () => Promise<TData>,
  enabled: boolean = true,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void,
  closeFeedback: () => void,
  onSuccess: (data: TData) => void = () => {},
  onError: (error: AxiosError) => void = () => {}
) => {
  return useQuery<TData>(queryKey, queryFn, {
    keepPreviousData: true,
    staleTime: 30 * 60 * 1000,
    enabled: enabled,
    onSuccess: onSuccess,
    onError: (error: unknown) => {
      logError(error);

      const feedbackValues = createFeedbackInitValues();

      const message =
        (error as AxiosError)?.response?.data?.message ||
        `There has been an error fetching ${queryKey[0]} data. Please check your input and try again.`;

      feedbackInit({
        ...feedbackValues,
        message
      });

      onError(error as AxiosError);
      setTimeout(() => {
        closeFeedback();
      }, 5000);
    }
  });
};

export const useFetchKoraRate = (
  fromCurrency: string,
  toCurrency: string,
  newMarkUpValue: string,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void,
  closeFeedback: () => void
) =>
  useFetchData<IKoraRateResponse>(
    ['KORA_RATE', fromCurrency, toCurrency, newMarkUpValue],
    () => api.fetchKoraRate(fromCurrency, toCurrency, newMarkUpValue),
    !!newMarkUpValue,
    feedbackInit,
    closeFeedback
  );

export const useFetchLastSetMarkup = (
  fromCurrency: string,
  toCurrency: string,
  refetchKoraRate: () => void,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void
) =>
  useFetchData<IMarkupResponse>(
    ['MARK_UP', fromCurrency, toCurrency],
    () => api.getMarkUp(fromCurrency, toCurrency),
    true,
    feedbackInit,
    () => refetchKoraRate()
  );

export const useFetchProviderRate = (
  fromCurrency: string,
  toCurrency: string,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void,
  closeFeedback: () => void
) =>
  useFetchData<IProviderRateResponse>(
    ['PROVIDER_RATE', fromCurrency, toCurrency],
    () => api.fetchProviderRate(fromCurrency, toCurrency),
    true,
    feedbackInit,
    closeFeedback
  );

export const useUpdateMarkUp = (
  refetchMarkup: () => void,
  feedbackInit: (values: { [key: string]: string | boolean | undefined }) => void
) => {
  return useMutation<void, unknown, IUpdateMarkUpData>((updateMarkUpData: IUpdateMarkUpData) => api.updateMarkUp(updateMarkUpData), {
    onSuccess: () => refetchMarkup(),
    onError: error => {
      logError(error);
      const feedbackValues = createFeedbackInitValues();
      feedbackInit({
        ...feedbackValues,
        message: (error as AxiosError)?.response?.data?.message || 'There has been an error adding Markup'
      });
    }
  });
};
