import React, { useEffect, useReducer, useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';

import LoadingPlaceholder from '+containers/Dashboard/Shared/LoadingPlaceHolder';
import { ScrollableTabList, Tab, TabPanel, TabPanels, Tabs } from '+containers/Dashboard/Shared/Tabs';
import { useFeedbackHandler, useSearchQuery, useSetUserAccess } from '+hooks';
import APIRequest from '+services/api-services';
import LargeExportModal from '+shared/LargeExportModal';
import Table from '+shared/Table';
import {
  APIDownload,
  capitalize,
  capitalizeFirst,
  filteredOutObjectProperty,
  formatAmount,
  getDate,
  history,
  isAllowed,
  logError,
  queriesParams,
  renameObjectKeys,
  switchStatus
} from '+utils';
import useStore from '+zustandStore';

import { switchTabData } from '../data';
import { issuingMerchantTabList, pciDssLevels, switchDateFilterParam } from './data';

import lowRiskIcon from '+assets/img/dashboard/low-risk-icon.svg';
import merchantIcon from '+assets/img/dashboard/merchant-icon.svg';
import midRiskIcon from '+assets/img/dashboard/mid-risk-icon.svg';

import './index.scss';

const api = new APIRequest();

export default function IssuedMerchantDetails() {
  const { id: merchantId } = useParams();
  const userAccess = useSetUserAccess();
  const { feedbackInit } = useFeedbackHandler();
  const searchQuery = useSearchQuery();
  const risk = 'mid';
  const { profile } = useStore(state => state);
  const [showLargeExportModal, setLargeExportModal] = useState<boolean>(false);

  const permittedTabs = issuingMerchantTabList
    .map(tab => {
      if (tab.key === 'issuing_history' && !isAllowed(userAccess, ['card_issuance_wallet_history.view'])) return null;
      if (['merchant_reserved_cards', 'merchant_customer_cards'].includes(tab.key) && !isAllowed(userAccess, ['card_issuance_cards.view']))
        return null;
      if (tab.key === 'disputes' && !isAllowed(userAccess, ['card_issuance_chargebacks.view'])) return null;
      if (tab.key === 'merchant_card_transactions' && !isAllowed(userAccess, ['card_issuance_transactions.view'])) return null;
      return tab;
    })
    .filter(Boolean);
  const page = searchQuery.value.page ?? '1';
  const limit = searchQuery.value.limit ?? 10;
  const status = searchQuery.value.status ?? [];
  const activeTab = searchQuery.value.tab ?? permittedTabs[0]?.key;
  const activeTabProps = issuingMerchantTabList.find(tab => tab.key === activeTab);
  const activeSubtab = activeTabProps?.subTabs?.[0].key ?? null;
  const activeSubtabProps = activeTabProps?.subTabs?.find(tab => tab.key === activeSubtab);
  const activeTabHasAtLeastOneSubtab = !!activeTabProps?.subTabs?.length;
  const computeViewPermissions = () => {
    if (activeTab === 'issuing_history' && !isAllowed(userAccess, ['card_issuance_wallet_history.view'])) return false;
    if (['merchant_reserved_cards', 'merchant_customer_cards'].includes(activeTab) && !isAllowed(userAccess, ['card_issuance_cards.view']))
      return false;
    if (activeSubtab === 'merchant_issuance_chargebacks' && !isAllowed(userAccess, ['card_issuance_chargebacks.view'])) return false;
    if (activeTab === 'merchant_card_transactions' && !isAllowed(userAccess, ['card_issuance_transactions.view'])) return false;
    return true;
  };
  const computeExportPermissions = () => {
    if (activeTab === 'issuing_history' && !isAllowed(userAccess, ['card_issuance_wallet_history.export'])) return false;
    if (
      ['merchant_reserved_cards', 'merchant_customer_cards'].includes(activeTab) &&
      !isAllowed(userAccess, ['card_issuance_cards.export'])
    )
      return false;
    if (activeSubtab === 'merchant_issuance_chargebacks' && !isAllowed(userAccess, ['card_issuance_chargebacks.export'])) return false;
    if (['merchant_card_transactions'].includes(activeTab) && !isAllowed(userAccess, ['card_issuance_transactions.export'])) return false;
    return true;
  };

  const filteredSortingParams = filteredOutObjectProperty(searchQuery.value, [
    queriesParams.tab,
    queriesParams.page,
    queriesParams.limit,
    queriesParams.sorterType,
    queriesParams.status,
    queriesParams.subTab,
    queriesParams.activeCurrency
  ]);

  const paramsToRename = switchDateFilterParam(activeSubtab || activeTab);

  const sortingParams = {
    ...renameObjectKeys(filteredSortingParams, paramsToRename),
    status: typeof status === 'string' ? [status] : status
  };

  const [state, setState] = useReducer(
    (prev: Record<string, any>, next: Record<string, any>) => {
      return { ...prev, ...next };
    },
    {
      tableData: {},
      isFilterVisible: false,
      sorterType: null,
      showModal: false,
      activeCurrency: 'USD'
    }
  );
  const { data: merchantDetails, isFetching: merchantDetailsIsFetching } = useQuery(
    ['ISSUING_MERCHANT_DETAILS', merchantId],
    () => api.fetchIssuingMerchantDetails(merchantId),
    {
      onError: () => {
        feedbackInit({
          message: 'There has been an error fetching the merchant details',
          type: 'danger'
        });
      },
      enabled: !!isAllowed(userAccess, ['card_issuing_merchant_details.view'])
    }
  );
  useEffect(() => {
    if (activeTabHasAtLeastOneSubtab) searchQuery.setQuery({ subTab: activeTabProps.subTabs?.[0].key as string });
  }, [activeTab]);

  const {
    reference,
    kora_id: koraId,
    name,
    status: issuingStatus,
    plan,
    issued_cards: issuedCards,
    reserved_cards: reservedCards,
    wallet_balance: balance,
    risk_level: riskLevel,
    date_created: dateCreated,
    pci_dss_level: pciDssLevel
  } = merchantDetails || {};

  const switchOptionalArgs = () => {
    switch (activeSubtab || activeTab) {
      case 'merchant_customer_cards':
        return [koraId, 'customer'];
      case 'merchant_reserved_cards':
        return [koraId, 'reserved'];
      case 'merchant_card_transactions':
      case 'merchant_issuance_chargebacks':
      case 'issuing_history':
        return [koraId];
      default:
        return [];
    }
  };

  const activeTableRequest = activeSubtab ? activeSubtabProps : activeTabProps;

  const { data, isFetching, refetch } = useQuery(
    [activeTableRequest?.queryKey, koraId, activeTab, page, limit, state.activeCurrency, searchQuery.value],
    () => activeTableRequest?.query?.(...switchOptionalArgs(), page, limit, sortingParams),
    {
      onError: () => {
        feedbackInit({
          message: `There has been an error fetching the requested data`,
          type: 'danger'
        });
      },
      enabled: !!koraId && computeViewPermissions()
    }
  );
  const exportRecords = async (format: string, close: () => void, fieldsToExport: string[]) => {
    try {
      const res: Awaited<Blob> = await (activeSubtabProps ?? activeTabProps)?.query?.(
        ...switchOptionalArgs(),
        page,
        limit,
        sortingParams,
        true,
        format,
        fieldsToExport
      );
      if (res?.status === 202) {
        setLargeExportModal(true);
      } else {
        const type = format === 'csv' ? 'csv' : 'xlsx';
        APIDownload(res, `card-transactions_${getDate(Date.now())}`, type);
        feedbackInit({
          title: 'Export Successful',
          message: <> - Successfully exported record.</>,
          type: 'success'
        });
      }
      close();
    } catch (error) {
      logError(error);
      feedbackInit({
        title: 'Export Failed',
        message: `There has been an error downloading your record`,
        type: 'danger',
        componentLevel: true
      });
    }
  };

  useEffect(() => {
    const tab = activeTabHasAtLeastOneSubtab ? activeSubtab : activeTab;
    setState({ tableData: switchTabData(tab, searchQuery.value, data?.paging || {}) });
  }, [activeTab, activeSubtab, data?.paging]);

  const summaryInfo: Record<string, string | JSX.Element> = {
    [`Issuing Balance (${state.activeCurrency})`]: formatAmount(balance ?? 0),
    'Cards Issued': issuedCards ?? '--',
    'Reserved Cards': reservedCards ?? '--',
    'Issuing Status': <b style={{ color: '#24B314' }}>{capitalizeFirst(issuingStatus ?? '--')}</b>,
    'Started Issuing': getDate(dateCreated) ?? '--'
  };

  const securityDetails: Record<string, number | string | JSX.Element> = {
    'Risk Rating': (
      <span className={switchStatus(risk)}>
        <img alt="risk icon" src={['high', 'low'].includes(riskLevel) ? lowRiskIcon : midRiskIcon} />
        {risk}
      </span>
    ),
    'PCI Level': pciDssLevel ? pciDssLevels[pciDssLevel as keyof typeof pciDssLevels] : '--'
  };

  const tableDataKeys = Object.keys(state.tableData || {}).length > 0 ? Object.keys(state.tableData.fields({}).data) : [];

  return (
    <>
      <LargeExportModal close={() => setLargeExportModal(false)} email={profile.email} visible={showLargeExportModal} />

      <div className="issued-merchant-details__comp stack-md">
        <div>
          <button
            type="button"
            className="btn btn-link mb-3 pl-0"
            onClick={() => history.push('/dashboard/card-issuance?tab=issuing_merchants')}
          >
            <i className="os-icon os-icon-arrow-left7" />
            <span>Back</span>
          </button>
        </div>
        <section>
          <div className="merchant-details-heading">
            <div className="merchant-name">
              <div className="merchant-icon">
                <img src={merchantIcon} alt="avatar" className="image" />
                Merchant
              </div>
              <div className="name-text">
                <h3 className="header-text" aria-label="name-text">
                  {name}
                </h3>
                <p className="description">
                  ID: {reference} • {capitalize(plan?.name)}
                </p>
              </div>
            </div>

            <div className="merchant-security-details">
              {Object.entries(securityDetails).map(
                ([key, value]): JSX.Element => (
                  <span className="security-detail-element" key={key}>
                    {key}: <b>{value}</b>
                  </span>
                )
              )}
            </div>
          </div>
          <hr />

          {merchantDetailsIsFetching ? (
            <LoadingPlaceholder type="text" content={10} />
          ) : (
            <section className="merchant-summary --info">
              {Object.entries(summaryInfo).map(
                ([key, value]): JSX.Element => (
                  <div className="merchant-summary --item" key={key}>
                    <h3 className="merchant-summary --label">{key}</h3>
                    <p className="merchant-summary --value">{value}</p>
                  </div>
                )
              )}
            </section>
          )}
        </section>

        <Tabs
          onChange={tabVal => {
            searchQuery.setQuery({ tab: tabVal }, true);
          }}
          defaultValue={activeTab as string}
          className="mt-5"
        >
          <div className="nav tablist">
            <ScrollableTabList>
              {permittedTabs?.map(value => {
                return (
                  <Tab value={value?.key as string} key={value?.key}>
                    {value?.label}
                  </Tab>
                );
              })}
            </ScrollableTabList>
          </div>

          <TabPanels className="mt-5">
            {permittedTabs.map(value => (
              <TabPanel key={value?.key} value={value?.key as string}>
                {activeTabHasAtLeastOneSubtab ? (
                  <div className="subtab-panel" role="tabpanel" id={`tabpanel-${activeSubtab}`}>
                    Currently viewing:
                    <ul role="tablist">
                      {activeTabProps?.subTabs?.map(({ label, key }) => {
                        return (
                          <li className="nav-item" key={key} role="presentation">
                            <div
                              role="button tab"
                              onClick={() => {
                                searchQuery.setQuery({ tab: activeTab, limit: '10', page: '1', subTab: key }, true);
                              }}
                              className={`nav-link ${key === activeSubtab && 'active'}`}
                              aria-selected={key === activeSubtab}
                              aria-controls={`tabpanel-${activeSubtab}`}
                            >
                              {label}
                            </div>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                ) : null}

                <section
                  className="transaction_table_comp table-container"
                  role="tabpanel"
                  id={activeSubtab ? `tabpanel-${activeSubtab}` : `tabpanel-${activeTab}`}
                >
                  <Table
                    header={null}
                    className={`--history-table ${state.tableData?.className || ''}`}
                    tableHeadings={tableDataKeys}
                    loading={isFetching}
                    data={data?.data || []}
                    renderFields
                    hasPagination
                    filterType={state.tableData?.type || ''}
                    filterName={state.tableData?.filterTitle || ''}
                    filterHasAdvancedFilter={computeViewPermissions()}
                    filterExportAction={exportRecords}
                    filterTotalCount={data?.paging?.total_items}
                    filterShowExport={computeExportPermissions()}
                    filterActiveCurrency={data?.currency || state.activeCurrency}
                    type={state.tableData?.type || state.tableData?.filterType}
                    annotation={state.tableData?.annotations}
                    current={data?.paging?.current}
                    rowKey={state.tableData?.rowKey}
                    rowURL={state.tableData?.rowURL}
                    pageSize={data?.paging?.page_size}
                    totalItems={data?.paging?.total_items}
                    limitAction={(c: number) => searchQuery.setQuery({ limit: String(c) })}
                    actionFn={(c: number) => searchQuery.setQuery({ page: String(c) })}
                    emptyStateHeading={state.tableData?.emptyStateHeading || ''}
                    emptyStateMessage={
                      <>
                        <span>{state.tableData?.emptyStateMessage || ''}</span>
                        {computeViewPermissions() && (
                          <button type="button" className="refetch-button" onClick={() => refetch()}>
                            <i className="os-icon os-icon-rotate-ccw mr-1" />
                            Refresh
                          </button>
                        )}
                      </>
                    }
                  >
                    {state.tableData?.fields}
                  </Table>
                </section>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      </div>
    </>
  );
}
