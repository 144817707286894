/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useReducer, useState } from 'react';

import BulkActionComponent from '+shared/BulkAction';
import FilterComponent from '+shared/Filter';
import useStore from '+store/zustand';
import { FileFormatType, ITableTotalProps, Nullable, TableTypes } from '+types';
import { capitalizeRemovedash, history, logError } from '+utils';

import BulkTableNotify from './BulkTableNotify';
import CursorPagination from './CursorPagination';
import CustomCheckbox from './CustomCheckbox';
import EmptyState from './EmptyState';
import LoadingPlaceholder from './LoadingPlaceHolder';
import PaginationComponent from './Pagination';

import './index.scss';

interface IInitialState<K> {
  selectedRows: (K[keyof K] | null)[];
  showNotification: boolean;
  isCompleted: boolean;
}

/**
 *
 * @remarks
 * The handleFilterQuery is now optional, you only need it when you want to get
 * filter queries without setting them to the URL
 *
 * @params {string} storedState - For storing activeCurrency used in bulkAction
 *
 */

const displaySuccessNotification = ['payout_reversal_approve', 'payout_reversal_decline'];
const Table = <T extends { reference?: string; id?: string | number }>(props: ITableTotalProps<T>): JSX.Element => {
  type ReferenceType = T[keyof T];
  // Initialize default props
  const state: ITableTotalProps<T> & { dataTestId?: string } = {
    tableHeadings: [],
    totalItems: null,
    pageSize: null,
    current: 1,
    limitAction: () => null,
    actionFn: () => null,
    hasBulkAction: false,
    annotation: '',
    emptyStateHeading: '',
    emptyStateMessage: '',
    isTableBordered: true,
    hasFilter: true,
    bulkAction: '',
    isRowClickable: true,
    showDateFilter: true,
    hideSelectedExport: false,
    showCheckbox: false,
    showExportModalDateRange: false,
    exportModalHeaderBottomBorder: false,
    exportModalScrollable: false,
    ...props
  };
  const [isGeneralCheckBoxClick, setIsGeneralCheckBoxClick] = useState<boolean>(false);
  const { bulkState, checkboxStatusesToShow, checkBoxStatusesKey, showCheckbox, checkBoxKey } = state;
  const completedAction = useStore(store => (store as { completedAction: string }).completedAction);
  const filterType = (): string => {
    const type = state?.filterType || state?.type;
    switch (type) {
      case 'payout':
        return 'pay-outs';

      default:
        return type as string;
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.KeyboardEvent<HTMLDivElement>, iter: T): Nullable<void> => {
    e.preventDefault();
    if (!state.isRowClickable) return null;
    if (state.rowFn) return state.rowFn(iter);
    if (state.rowURL) {
      if (state.rowKey?.includes('.')) {
        const keys = state.rowKey.split('.');
        return history.push(
          `${state.rowURL}/${keys[0] ? iter[keys[0] as keyof typeof iter][keys[1] as keyof (typeof iter)[keyof typeof iter]] : ''}${
            state.rowUrlQuery || ''
          }`,
          null
        );
      }
      return history.push(`${state.rowURL}/${state.rowKey ? iter[state.rowKey as keyof typeof iter] : ''}${state.rowUrlQuery || ''}`, null);
    }
    return null;
  };

  const type = state.subType || state.type;

  const [tableControls, setTableControls] = useReducer(
    (prev: Record<string, IInitialState<T>>, next: Record<string, IInitialState<T>>): Record<string, IInitialState<T>> => ({
      ...prev,
      ...next
    }),
    {
      [type as TableTypes]: {
        selectedRows: [],
        showNotification: false,
        isCompleted: false
      }
    }
  );

  const controls: IInitialState<T> = tableControls[type as TableTypes];
  const setControls = (states: Partial<IInitialState<T>>) => {
    if (controls) {
      setTableControls({
        [type as TableTypes]: {
          ...controls,
          ...states
        }
      });
    } else {
      setTableControls({
        [type as TableTypes]: {
          selectedRows: [],
          showNotification: false,
          isCompleted: false,
          ...states
        }
      });
    }
  };

  const handlingBulkActions = (reference: ReferenceType) => {
    return (bulkState?.[(checkBoxKey ?? 'reference') as keyof typeof bulkState] as ReferenceType[])?.includes(reference);
  };

  const isChecked = (reference: ReferenceType) => controls?.selectedRows?.includes(reference) || handlingBulkActions(reference);

  const checkAllBoxes = () => {
    if (showCheckbox && checkBoxStatusesKey && checkboxStatusesToShow && checkboxStatusesToShow?.length > 0 && !isGeneralCheckBoxClick) {
      setIsGeneralCheckBoxClick(true);
      const dataIds = state.data
        ?.filter(item => checkboxStatusesToShow.includes(item[checkBoxStatusesKey as keyof typeof item] as string))
        .map(item => (checkBoxKey ? item[checkBoxKey as keyof typeof item] : null));

      if (state.storeSelectedItem) {
        state.storeSelectedItem(dataIds as ReferenceType[]);
      }
      return setControls({ selectedRows: dataIds });
    }
    if (state.hasBulkAction && (!controls || controls?.selectedRows?.length !== state.data?.length)) {
      const dataIds = state?.data?.map((item: any) => item[checkBoxKey ?? 'reference']);
      return setControls({ selectedRows: dataIds });
    }
    if (state.hasBulkAction && controls?.selectedRows?.length === state.data?.length) {
      setControls({ selectedRows: [] });
    }
    if (showCheckbox && controls?.selectedRows?.length > 0 && isGeneralCheckBoxClick) {
      setControls({ selectedRows: [] });
      setIsGeneralCheckBoxClick(false);
      if (state.storeSelectedItem) {
        state.storeSelectedItem([]);
      }
    }
    return null;
  };

  const checkSingleRow = (reference: ReferenceType) => {
    if (!controls) {
      return setControls({ selectedRows: [reference] });
    }
    if (controls?.selectedRows?.includes(reference)) {
      const filterRows = controls?.selectedRows?.filter(item => item !== reference);
      setControls({ selectedRows: filterRows });
      if (state.storeSelectedItem) {
        state.storeSelectedItem(filterRows);
      }
    } else {
      setControls({ selectedRows: [...controls.selectedRows, reference] });
      if (state.storeSelectedItem) {
        state.storeSelectedItem([...controls.selectedRows, reference]);
      }
    }
    return null;
  };
  const onClearSelection = () => {
    if (showCheckbox) {
      setIsGeneralCheckBoxClick(false);
    }
    setControls({ selectedRows: [] });
  };

  const countTotalNumOfCheckBoxPerPage = (data: T[], key: string) => {
    try {
      const reports = data?.filter(
        (item: T) => checkboxStatusesToShow && checkboxStatusesToShow?.includes(item[key as keyof typeof item] as string)
      );
      if (reports) {
        return reports.length;
      }
      return 0;
    } catch (e) {
      logError(e);
    }
    return 0;
  };

  useEffect(() => {
    const showNotification =
      (completedAction === state.bulkAction && completedAction !== '') ||
      displaySuccessNotification.includes(completedAction) ||
      (bulkState?.references?.length ?? 0) > 0;
    if (showNotification) {
      setControls({
        showNotification: true
      });
    }
  }, [bulkState, completedAction]);

  useEffect(() => {
    if (state?.storedState?.activeCurrency) {
      setControls({
        selectedRows: []
      });
    }
  }, [state?.storedState?.activeCurrency]);

  return (
    <>
      {!controls || (controls?.selectedRows?.length === 0 && state.hasFilter) ? (
        <FilterComponent
          type={filterType() as TableTypes}
          activeCurrency={state.filterActiveCurrency as string}
          defaultStatus={state.filterDefaultStatus as string}
          keywordPlaceholder={state.filterKeywordPlaceholder}
          exportAction={state.filterExportAction}
          totalCount={state.totalItems ?? 0}
          annotation={state.annotation}
          handleFilterQuery={state.filterHandleFilterQuery}
          showExport={state.filterShowExport}
          QueryIDPlaceholder={state.filterQueryIDPlaceholder}
          hasAdvancedFilter={state.filterHasAdvancedFilter}
          hasBasicFilter={state.filterHasBasicFilter as boolean}
          amountPlaceholder={state.filterAmountPlaceholder}
          filterName={state.filterName}
          showDateFilter={state.showDateFilter}
          filterOptions={state.filterOptions}
          dateRange={state.showExportModalDateRange}
          exportModalHeaderBottomBorder={state.exportModalHeaderBottomBorder}
          exportModalScrollable={state.exportModalScrollable}
          loading={props.loading}
          moreFilterBtn={props.moreFilterBtn}
        />
      ) : null}

      {controls?.selectedRows?.length > 0 && (
        <BulkActionComponent
          type={state.type as TableTypes}
          subType={state.subType as string}
          isCompleted={controls?.isCompleted}
          toggleActionCompleted={value => setControls({ isCompleted: value })}
          exportAction={(format: FileFormatType, close: () => void, FieldsToExport: string | string[]) =>
            state?.filterExportAction?.(format, close, FieldsToExport, controls.selectedRows as string[])
          }
          totalCount={controls?.selectedRows?.length}
          selectedRows={controls?.selectedRows as string[]}
          clearSelection={onClearSelection}
          disableBulkAction={false}
          action={state?.bulkAction as string}
          hideExport={state?.hideSelectedExport}
          showModal={state?.showBulkActionModal}
          onSuccessBulkAction={state?.onSuccessBulkAction}
          showSpinnerIconAction={state?.showBulkActionSpinnerAction}
        />
      )}

      <section className="table__comp" data-testid={state?.dataTestId || 'table_comp'}>
        {state.header && (
          <div className="box-header pt-4 pb-3 ml-3" style={{ fontWeight: 600 }}>
            {state.header}
          </div>
        )}

        <div
          className={`${state.isTableBordered ? 'table-wrapper' : ''} ${state.tableWrapperClassName || ''}  ${
            state.hasPagination ? '--has-pagination' : ''
          }`}
        >
          {state.loading ? (
            <LoadingPlaceholder type="table" background={state.loaderBackground || ''} content={3} />
          ) : state.data?.length === 0 || !state.children || state.hideTable ? (
            <EmptyState heading={state.emptyStateHeading} message={state.emptyStateMessage} />
          ) : (
            <>
              <div
                className={`div-table pb-3 ${state.className || ''} ${
                  state.hasBulkAction || state.showCheckbox ? 'bulk-action-heading' : ''
                } --heading`}
                hidden={state.hideTableHeadings}
              >
                {state.hasBulkAction ||
                (state.showCheckbox && state?.data && countTotalNumOfCheckBoxPerPage(state?.data, checkBoxStatusesKey || '') > 0) ? (
                  <div>
                    <CustomCheckbox
                      text=""
                      onChange={checkAllBoxes}
                      checked={
                        state.hasBulkAction
                          ? controls?.selectedRows?.length === state?.data?.length
                          : state.data &&
                            controls?.selectedRows?.length === countTotalNumOfCheckBoxPerPage(state?.data, checkBoxStatusesKey || '')
                      }
                    />
                  </div>
                ) : null}
                {state.showCheckbox && state.data && countTotalNumOfCheckBoxPerPage(state.data, checkBoxStatusesKey || '') === 0 && <div />}
                {state.tableHeadings?.map((heading: string, index: number) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <div key={index} data-testid="columnheader">
                    {capitalizeRemovedash(heading)} {state.tableHeaderIcon?.[heading.toLowerCase()]}
                  </div>
                ))}
              </div>

              <BulkTableNotify
                action={state.bulkAction as string}
                isOpen={controls?.showNotification}
                toggle={() => setControls({ showNotification: !controls?.showNotification })}
              />
              {!state.renderFields && <div>{state.children}</div>}

              {state.renderFields && (
                <>
                  {state?.data?.map((item, index) => (
                    <div
                      key={`${item.reference}-${index}` ?? item.id ?? index}
                      className={`div-table ${state.className || ''} ${
                        state.hasBulkAction || state.showCheckbox ? 'bulk-action-row' : ''
                      } --row`}
                    >
                      {state.hasBulkAction && (
                        <div className={`--column user-with-avatar edit-merchant `} style={{ minWidth: 40 }}>
                          <CustomCheckbox
                            text=""
                            checked={checkBoxKey ? isChecked(item?.[checkBoxKey as keyof typeof item] as ReferenceType) : undefined}
                            onChange={() => checkSingleRow((checkBoxKey ? item[checkBoxKey as keyof typeof item] : '') as ReferenceType)}
                            disabled={handlingBulkActions((checkBoxKey ? item[checkBoxKey as keyof typeof item] : '') as ReferenceType)}
                          />
                        </div>
                      )}
                      {state.showCheckbox &&
                      checkBoxStatusesKey &&
                      checkboxStatusesToShow?.includes(item[checkBoxStatusesKey as keyof typeof item] as string) ? (
                        <div className={`--column user-with-avatar edit-merchant `}>
                          <CustomCheckbox
                            text=""
                            checked={checkBoxKey ? isChecked(item[checkBoxKey as keyof typeof item]) : undefined}
                            onChange={() => checkSingleRow((checkBoxKey ? item[checkBoxKey as keyof typeof item] : '') as ReferenceType)}
                            disabled={handlingBulkActions((checkBoxKey ? item[checkBoxKey as keyof typeof item] : '') as ReferenceType)}
                          />
                        </div>
                      ) : (
                        state.showCheckbox && <div className={`--column user-with-avatar edit-merchant `} />
                      )}
                      {Object.entries(state.children?.(item)?.data || {}).map(([key, value]: [string, unknown], i) => (
                        <div
                          key={key}
                          className={`--column ${key === 'status' ? '--txn-status' : ''} user-with-avatar edit-merchant `}
                          style={{
                            ...state.children?.(item)?.style,
                            display: 'flex',
                            alignItems: 'center'
                          }}
                          data-testid={`table-row-${index}-column-${i}`}
                          role="button"
                          tabIndex={0}
                          onClick={e => handleClick(e, item)}
                          onKeyUp={e => handleClick(e, item)}
                        >
                          <span className="body-row-header">{capitalizeRemovedash(key)}:</span>
                          {value as string | React.ReactNode}
                        </div>
                      ))}
                    </div>
                  ))}
                </>
              )}
              {state.hasPagination && state.actionFn && !state.cursors && (
                <PaginationComponent
                  currentPage={state.current as number}
                  pagingTotalItems={state.totalItems as number}
                  limitAction={state.limitAction}
                  pageSize={state.pageSize as number}
                  action={state.actionFn}
                  annotation={state.annotation}
                  disabled={controls?.selectedRows?.length > 0}
                />
              )}
              {state.hasPagination && state.cursors && (
                <CursorPagination
                  annotation={state.annotation}
                  disabled={controls?.selectedRows?.length > 0}
                  cursors={state.cursors}
                  totalCount={state?.data?.length ?? 0}
                />
              )}
            </>
          )}
        </div>
      </section>
    </>
  );
};

export default Table;
