import React, { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import useFeedbackHandler from '+hooks/useFeedbackHandler';
import APIRequest from '+services/api-services';
import LoadingPlaceholder from '+shared/LoadingPlaceHolder';
import Modal from '+shared/Modal';
import ReactSelectDropdown from '+shared/ReactSelectDropdown';
import { IKoraRateResponse, IProviderRateResponse } from '+types';
import { getFullDate, getTime, switchCurrencyPair } from '+utils';

import { useFetchKoraRate, useFetchLastSetMarkup, useFetchProviderRate, useUpdateMarkUp } from './currencyExchangeHelper';

import './index.scss';

const api = new APIRequest();

interface ModalProps {
  close: () => void;
}

type actionType = 'updateSettings' | 'saveSettings';

export default function ManageExchangeRate({ close }: ModalProps): JSX.Element {
  const { feedbackInit, closeFeedback } = useFeedbackHandler();
  const [stage, setStage] = useState<actionType>('updateSettings');
  const [formValue, setFormValue] = useState({
    currency: 'USD'
  });
  const [state, setState] = useState({
    loading: false,
    newMarkUp: {
      value: '',
      isValid: false
    }
  });

  const currencyLists = Object.entries(switchCurrencyPair).map(([currencyCode, currencyName]) => ({
    label: currencyName,
    value: currencyCode
  }));

  const [fromCurrency, toCurrency] = switchCurrencyPair[formValue.currency as keyof typeof switchCurrencyPair]?.split('-');

  const updateMarkUpData = {
    from_currency: fromCurrency,
    to_currency: toCurrency,
    base_markup: state.newMarkUp.value
  };

  const { data: providerRate } = useFetchProviderRate(fromCurrency, toCurrency, feedbackInit, closeFeedback);

  const { data: lastSetMarkup, refetch } = useFetchLastSetMarkup(fromCurrency, toCurrency, () => {}, feedbackInit);

  const { data: koraRate, refetch: refetchKoraRate } = useFetchKoraRate(
    fromCurrency,
    toCurrency,
    state.newMarkUp.value,
    feedbackInit,
    closeFeedback
  );

  const { mutate: updateMarkUp } = useUpdateMarkUp(refetch, feedbackInit);

  const dateSet = lastSetMarkup?.data?.last_updated_at ?? null;
  const value = lastSetMarkup?.data?.value ?? null;

  const { provider_rate } = (providerRate?.data as IProviderRateResponse['data']) || { provider_rate: null };
  const { kora_rate } = (koraRate?.data as IKoraRateResponse['data']) || { kora_rate: null };

  const debouncedFetchKoraRate = useDebouncedCallback(() => {
    refetchKoraRate();
  }, 1000);

  useEffect(() => {
    if (fromCurrency && toCurrency) {
      debouncedFetchKoraRate();
    }
  }, [fromCurrency, toCurrency, debouncedFetchKoraRate]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value.replace(/\s/g, '');
    const regex = /^\d+(\.\d{0,2})?$/;

    const isValid = regex.test(inputValue.trim()) && parseFloat(inputValue) > 0;

    setState(prevState => ({
      ...prevState,
      newMarkUp: {
        value: inputValue,
        isValid
      }
    }));

    if (isValid) {
      debouncedFetchKoraRate();
    }
  };

  const canSetNewMarkUp = !state.newMarkUp.isValid || kora_rate <= 0;

  const showEditRate = () => {
    return (
      <>
        <div className="form-group">
          <ReactSelectDropdown
            label="Select Currency Pair"
            placeholder="-- Select currency --"
            options={currencyLists}
            value={formValue.currency}
            onChange={value => setFormValue({ currency: value as string })}
          />
        </div>
        <div className="form-group">
          <label htmlFor="Payment Channel">
            <span>Payment Channel</span>
          </label>
          <input type="text" name="" placeholder="Exchange" className="form-control" disabled />
        </div>
        <div className="rate-container">
          {state.loading ? (
            <LoadingPlaceholder type="text" content={10} />
          ) : (
            lastSetMarkup && (
              <div className="saved-bank-container p-3">
                <div className="last_rate">
                  <ul>
                    <li>
                      <div>
                        <span className="">Last Set Rate:</span>
                      </div>
                      <div>
                        <span className="last_rate_value">{value ? `${value}%` : 'N/A'}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <span>Date Set:</span>
                      </div>
                      <div>
                        <span className="last_set_date">{dateSet ? `${getFullDate(dateSet)} ${getTime(dateSet)}` : 'N/A'}</span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <span>Provider Rate:</span>
                      </div>
                      <div>
                        <span className="last_set_date">
                          {!state.loading ? (provider_rate != null ? `${provider_rate}` : 'N/A') : '--'}
                        </span>
                      </div>
                    </li>
                    <li>
                      <div>
                        <span>Kora Rate:</span>
                      </div>
                      <div>
                        <span className="last_set_date">{!state.loading ? (kora_rate != null ? `${kora_rate}` : 'N/A') : '--'}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            )
          )}
        </div>
        <div className="form-group">
          <label htmlFor="New markup">
            <span>New Markup on Base Rate </span>
          </label>
          <input
            type="number"
            name="markup"
            min={1}
            placeholder="Enter new mark up"
            className="form-control"
            value={state.newMarkUp.value}
            onChange={handleInputChange}
          />
        </div>
      </>
    );
  };

  const saveRate = () => {
    return (
      <>
        <div className="rate-container">
          <div className="saved-bank-container p-3">
            <div className="last_rate">
              <ul>
                <li>
                  <span>Currency Pair:</span>
                  <span>
                    {fromCurrency} - {toCurrency}
                  </span>{' '}
                </li>
                <li>
                  <span>New Rate Mark Up:</span>
                  <span>{`${state.newMarkUp.value}%`}</span>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  const modalStages = {
    updateSettings: {
      heading: 'Edit Kora Exchange Rate for USD',
      description:
        'Modifying this rate rule will apply the updated fees to future transactions that fall within the specified range for all merchants.',
      content: showEditRate(),
      secondButtonActionIsTerminal: false,
      secondButtonText: 'Continue',
      secondButtonDisable: canSetNewMarkUp,
      secondButtonAction: () => {
        setStage('saveSettings');
      }
    },
    saveSettings: {
      heading: 'Save Changes?',
      description: 'Please confirm that you want to save the changes made.',
      content: saveRate(),
      firstButtonText: 'Go back',
      firstButtonAction: () => setStage('updateSettings'),
      secondButtonText: 'Yes, Save',
      secondButtonActionIsTerminal: true,
      secondButtonAction: async () => {
        try {
          updateMarkUp(updateMarkUpData);
          setStage('saveSettings');
        } catch {
          feedbackInit;
        }
      },
      completedHeading: 'Saved',
      completedDescription: 'The rates configuration has been saved.',
      completedActionText: 'Dismiss',
      secondaryCompletedModal: true
    }
  };

  const props = {
    close,
    size: 'md',
    ...(modalStages[stage] || {})
  };

  return <Modal {...props} />;
}
