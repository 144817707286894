import React, { useEffect } from 'react';
import { useHistory, Redirect, Switch, Route, Link, RouteComponentProps } from 'react-router-dom';
import { isAllowed } from '+utils';
import useSetUserAccess from '+hooks/useSetUserAccess';
import SettlementsSettings from './SettlementsSettings';
import FeesComponent from './Fees';
import TransactionLimits from './TransactionLimits';

interface ILocationState {
  pathname: string;
}

interface ISettingsComponentProps {
  location: RouteComponentProps<{}, {}, ILocationState>['location'];
}
function SettingsComponent({ location }: ISettingsComponentProps) {
  const history = useHistory();
  const userAccess = useSetUserAccess();

  useEffect(() => {
    handleRedirection();
  }, [userAccess]);

  // eslint-disable-next-line consistent-return
  const handleRedirection = () => {
    if (isAllowed(userAccess, ['default_fee_config.view', 'default_fee_config.update'])) {
      return history.push('/dashboard/settings/fees');
    }
    if (isAllowed(userAccess, ['default_settlement_config.view', 'default_settlement_config.update'])) {
      return history.push('/dashboard/settings/settlements');
    }
  };

  if (!userAccess) return <div />;

  return (
    <div className="content-i">
      <div className="content-box">
        <div className="os-tabs-w">
          <div className="os-tabs-controls os-tabs-complex">
            <ul className="nav nav-tabs">
              {isAllowed(userAccess, ['default_fee_config.view', 'default_fee_config.update']) && (
                <li className="nav-item">
                  <Link
                    aria-expanded="false"
                    className={location.pathname === '/dashboard/settings/fees' ? 'nav-link active' : 'nav-link'}
                    data-toggle="tab"
                    to="/dashboard/settings/fees"
                  >
                    <span className="tab-label">Default Fees</span>
                  </Link>
                </li>
              )}
              {isAllowed(userAccess, ['default_settlement_config.view', 'default_settlement_config.update']) && (
                <li className="nav-item">
                  <Link
                    aria-expanded="false"
                    className={location.pathname === '/dashboard/settings/settlements' ? 'nav-link active' : 'nav-link'}
                    data-toggle="tab"
                    to="/dashboard/settings/settlements"
                  >
                    <span className="tab-label">Settlements</span>
                  </Link>
                </li>
              )}
              <li className="nav-item">
                <Link
                  aria-expanded="false"
                  className={location.pathname === '/dashboard/settings/transaction-limits' ? 'nav-link active' : 'nav-link'}
                  data-toggle="tab"
                  to="/dashboard/settings/transaction-limits"
                >
                  <span className="tab-label">Transaction Limits</span>
                </Link>
              </li>
            </ul>
          </div>
          <div>
            <Switch>
              <Route path="/dashboard/settings/fees" component={FeesComponent} />

              <Route path="/dashboard/settings/settlements" component={SettlementsSettings} />

              <Route path="/dashboard/settings/transaction-limits" component={TransactionLimits} />

              <Redirect to="/dashboard/access-denied" />
            </Switch>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SettingsComponent;


