import React, { useRef, useState } from 'react';

import useFocusTrap from '+dashboard/Shared/hooks/useFocusTrap';

import FeedbackHandler from './FeedBackHandler';

import completed from '+assets/img/dashboard/completed-tick.svg';

export interface IModalProps {
  close: (stage?: string) => void;
  visible?: boolean;
  themeColor?: string;
  heading?: string;
  description?: string | React.ReactNode;
  content: string | React.ReactNode;
  showButtons?: boolean;
  firstButtonText?: string;
  secondButtonText?: string;
  closeAction?: () => void;
  firstButtonColor?: string;
  secondButtonColor?: string;
  firstButtonTextColor?: string;
  showSecondButton?: boolean;
  firstButtonAction?: () => void;
  secondButtonAction?: () => void;
  firstButtonDisable?: boolean;
  secondButtonDisable?: boolean;
  secondButtonStyles?: React.CSSProperties;
  completedHeading?: string;
  completedDescription?: string | Element;
  completedImage?: React.ReactNode;
  size?: 'lg' | 'md' | 'sm' | ' mdBase';
  maxHeight?: 'lg' | 'md' | 'sm';
  secondButtonActionIsTerminal?: boolean;
  headerBottomBorder?: boolean;
  formCenter?: boolean;
  completedModalSize?: 'base' | 'mdBase' | 'md' | 'sm' | 'dialog' | 'dialog-centered';
  equalFooterBtn?: boolean;
  secondaryCompletedModal?: boolean;
  showImage?: boolean;
  completedAction?: () => void;
  completedActionText?: string;
  isScrollable?: boolean;
  hideSecondButton?: boolean;
  showCompleteActionText?: boolean;
  modalBodyClassName?: string;
  noFormCenter?: boolean;
  thirdButtonText?: string;
  thirdButtonAction?: () => void;
  showThirdButton?: boolean;
  thirdButtonStyles?: React.CSSProperties;
  firstButtonStyles?: React.CSSProperties;
  showCompletedModal?: boolean;
  footerButtonWidthIsAuto?: boolean;
}

const Modal = ({
  visible = true,
  close,
  heading,
  description,
  content,
  themeColor,
  showButtons = true,
  firstButtonText = 'Cancel',
  secondButtonText = 'Continue',
  closeAction,
  firstButtonColor,
  secondButtonColor,
  firstButtonTextColor,
  firstButtonAction,
  secondButtonAction,
  secondButtonActionIsTerminal = true,
  firstButtonDisable = false,
  secondButtonDisable = false,
  secondButtonStyles,
  showSecondButton = true,
  completedHeading = 'Success!',
  completedImage,
  completedDescription,
  headerBottomBorder,
  formCenter,
  completedModalSize,
  equalFooterBtn,
  secondaryCompletedModal,
  isScrollable,
  size = 'lg',
  maxHeight,
  hideSecondButton,
  showCompleteActionText = true,
  showImage = true,
  completedAction,
  completedActionText = 'Dismiss',
  modalBodyClassName = '',
  noFormCenter,
  thirdButtonText,
  thirdButtonAction,
  showThirdButton,
  thirdButtonStyles,
  firstButtonStyles,
  showCompletedModal = true,
  footerButtonWidthIsAuto = false
}: IModalProps): React.ReactElement => {
  const [modalState, setModalState] = useState({ loading: false, stage: 'init' });
  const modalContentRef = useRef(null);
  useFocusTrap(modalContentRef, visible, close);
  let actionColor: string;
  if (secondButtonColor) {
    actionColor = secondButtonColor;
  } else {
    actionColor = themeColor || '#2376F3';
  }

  const setModalToComplete = (showModal: boolean) =>
    setModalState({ ...modalState, stage: showModal ? 'complete' : 'init', loading: false });

  const Header = () => (
    <div className={`px-4 py-3 ${headerBottomBorder ? 'border-bottom' : ''}`}>
      <h4 className="onboarding-title" style={{ color: themeColor || '#292b2c' }}>
        {heading}
      </h4>
      <div className="onboarding-text">{description}</div>
    </div>
  );

  const ModalFooter = () => (
    <div className="px-4 pb-3" style={{ background: '#F9FBFD' }}>
      <div className="modal-footer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
        {showThirdButton && (
          <button
            data-testid="third-button"
            type="button"
            className="btn border-0 font-weight-bold"
            style={{
              padding: '12px 4px',
              width: 'auto',
              backgroundColor: 'transparent',
              color: '#2376F3',
              marginRight: '10px',
              ...thirdButtonStyles
            }}
            onClick={() => {
              if (thirdButtonAction) thirdButtonAction();
            }}
          >
            {thirdButtonText}
          </button>
        )}
        <div style={{ flex: 1, justifyContent: 'flex-end', alignItems: 'center', display: 'flex', gap: '10px' }}>
          <button
            data-testid="first-button"
            className={`btn border-0 font-weight-bold ${equalFooterBtn ? 'equal-button' : ''}`}
            data-dismiss="modal"
            type="button"
            style={{
              padding: '12px 34px',
              backgroundColor: '#EAF2FE',
              color: '#3E4B5B',
              width: hideSecondButton && !footerButtonWidthIsAuto ? '100%' : 'auto',
              ...firstButtonStyles
            }}
            disabled={firstButtonDisable}
            onClick={() => {
              if (!firstButtonAction) {
                setModalState({ ...modalState, stage: 'init' });
                return close();
              }
              return firstButtonAction();
            }}
          >
            {firstButtonText}
          </button>
          {!hideSecondButton && (
            <button
              data-testid="second-button"
              type="button"
              className={`btn font-weight-bold ${equalFooterBtn ? 'equal-button' : ''}`}
              disabled={secondButtonDisable}
              style={{
                padding: '12px 34px',
                width: 'auto',
                backgroundColor: actionColor,
                border: 'none',
                outline: actionColor,
                color: '#fff',
                ...secondButtonStyles
              }}
              onClick={async () => {
                try {
                  setModalState({ ...modalState, loading: true });
                  if (secondButtonAction) {
                    await secondButtonAction();
                  }
                  setModalState({ ...modalState, loading: false });
                  if (secondButtonActionIsTerminal) setModalToComplete(showCompletedModal);
                } catch (error) {
                  setModalState({ ...modalState, loading: false });
                }
              }}
            >
              {modalState.loading ? (
                <span className="spinner-border spinner-border-sm" style={{ marginRight: '0.5rem' }} role="status" aria-hidden="true" />
              ) : (
                secondButtonText
              )}
            </button>
          )}
        </div>
      </div>
    </div>
  );

  return (
    visible && (
      <div
        aria-hidden="true"
        className={`onboarding-modal modal ${visible ? 'show' : ''} fade`}
        role="dialog"
        tabIndex={-1}
        style={{ display: `${visible ? 'block' : 'none'}` }}
      >
        <div
          className={`modal-dialog  ${isScrollable ? 'modal-dialog-scrollable' : ''} modal-centered ${
            modalState.stage === 'complete'
              ? `modal-${completedModalSize || 'sm'} ${secondaryCompletedModal ? 'secondary-completed-modal' : ''}`
              : `modal-${size}`
          }`}
          role="document"
        >
          <div className="modal-content" style={{ maxHeight: maxHeight as string }} ref={modalContentRef}>
            <button
              data-testid="close-button"
              aria-label="Close"
              className="close"
              data-dismiss="modal"
              type="button"
              onClick={() => {
                setModalState({ ...modalState, stage: 'init' });
                if (closeAction) closeAction();
                // Passing modal stage gives the ability to implement dynamic action on the close button based on the stage.
                close(modalState.stage);
              }}
            >
              <span className="os-icon os-icon-close" />
            </button>

            {modalState.stage === 'init' && (
              <>
                <Header />
                <FeedbackHandler component />
                {content && (
                  <div className={`modal-body border-bottom ${modalBodyClassName}`}>
                    <form>
                      <div className={`form-center ${formCenter ? 'px-3 py-1' : ''} ${noFormCenter ? 'px-0' : ''}`}>{content}</div>
                    </form>
                  </div>
                )}
                {showButtons && <ModalFooter />}
              </>
            )}

            {modalState.stage === 'complete' &&
              (!secondaryCompletedModal ? (
                <>
                  <div className="px-4 py-3 border-bottom">
                    <h4 className="onboarding-title" style={{ color: '#292b2c', marginBottom: 0 }}>
                      {completedHeading}
                      <img
                        src={(completedImage as string) || completed}
                        alt="complete"
                        height={22}
                        width={22}
                        style={{ marginLeft: '10px', marginTop: '-6px' }}
                      />
                    </h4>
                  </div>
                  <div className="modal-body border-bottom">
                    <p>{completedDescription}</p>
                  </div>
                  {showCompleteActionText && (
                    <div style={{ background: '#F9FBFD', padding: '0 15px 18px 15px' }}>
                      <div className="modal-footer" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <button
                          className="btn border-0 font-weight-bold"
                          data-dismiss="modal"
                          type="button"
                          style={{ padding: '12px 34px', width: '100%', backgroundColor: '#2376F3', color: 'white' }}
                          onClick={() => {
                            setModalState({ ...modalState, stage: 'init' });
                            return close();
                          }}
                        >
                          Dismiss
                        </button>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="modal-body p-4 onboarding-content text-center">
                  <div className="element-invite">
                    <div className="invite-cont">
                      {showImage && (
                        <img
                          src={(completedImage as string) || completed}
                          alt="complete"
                          style={{ height: '3.78rem', width: '3.78rem', marginLeft: '10px' }}
                        />
                      )}
                      <h3 className="invite-header">{completedHeading}</h3>
                      <p className="invite-desc">{completedDescription}</p>

                      {showCompleteActionText && (
                        <div className="dismissal-btn">
                          <button
                            className="btn invite-btn"
                            type="button"
                            onClick={() => {
                              setModalState({ ...modalState, stage: 'init' });
                              return completedAction ? completedAction() : close('complete');
                            }}
                          >
                            {completedActionText}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
